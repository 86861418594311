import { DeleteOutlined, EditOutlined, PlusOutlined, SearchOutlined } from "@ant-design/icons";
import { Col, Divider, Form, Modal, Radio, Row, Space, Table, Tabs, Tag, message } from "antd";
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import BreadCrumb from "../../../components/BreadCrumb/BreadCrumb";
import Button from "../../../components/Button/Button";
import { CardContainer } from "../../../components/Card/CardContainer";
import Input from "../../../components/Input/Input";
import PageTitleNested from "../../../components/PageTitle/PageTitleNested";
import Select from "../../../components/Select/Select";
import TableContainer from "../../../components/Table/TableContainer";
import Text from "../../../components/Text/Text";
import {
  createUserShop,
  deleteUserShop,
  getUserShop,
  getUserShopById,
  updateUserShop,
} from "../../../datasource/UserShopDatasource";
import { USER_SHOP_MAPPING, USER_SHOP_MAPPING_COLOR } from "../../../definitions/UserShopStatus";
import { color } from "../../../resource";
import { getCustomerId } from "../../../datasource/CustomerDatasource";
import image from "../../../resource/image";
import styled from "styled-components";
import { checkCompany } from "../../../utility/CompanyName";

const Header = styled(Row)`
  border-radius: 8px;
  background-color: ${color.background1};
  padding: 10px;
  display: flex;
  gap: 16px;
  align-items: center;
`;

function UserCorporateShop(): JSX.Element {
  const userProfile = JSON.parse(localStorage.getItem("profile")!);
  const company = JSON.parse(localStorage.getItem("company")!);
  const navigate = useNavigate();
  const [form] = Form.useForm();

  const { pathname } = window.location;
  const pathSplit = pathname.split("/") as Array<string>;
  const id = pathSplit[3];

  const [isLoad, setIsLoad] = useState<boolean>(false);
  const [page, setPage] = useState<number>(1);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [tab, setTab] = useState<string>(
    checkCompany(company.companyCode) ? "APPROVED" : "WAIT_APPROVE",
  );
  const [isActive, setIsActive] = useState<any>(undefined);
  const [isApproveActive, setIsAprroveActive] = useState<any>(undefined);
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [isDelete, setIsDelete] = useState<boolean>(false);
  const [showCancel, setShowCancel] = useState<boolean>(false);
  const [edit, setEdit] = useState<any>({ isDelete: false });
  const [position, setPosition] = useState<string>("");
  const [searchText, setSearchText] = useState<string>("");
  const [idDelete, setIdDelete] = useState<string>("");
  const [cusName, setCusName] = useState<string>("");
  const [messageApi, contextHolder] = message.useMessage();
  const isInternal = checkCompany(company.companyCode);

  const [data, setData] = useState<any>({
    count: 0,
    data: [],
    summary: {},
  });

  const staticData = [
    {
      label: "คุณ",
      value: "คุณ",
      key: "1",
    },
    {
      label: "นาย",
      value: "นาย",
      key: "2",
    },
    {
      label: "นางสาว",
      value: "นางสาว",
      key: "3",
    },
    {
      label: "นาง",
      value: "นาง",
      key: "4",
    },
  ];

  const getDataCustomer = async () => {
    await getCustomerId({ customerId: id }).then((res) => {
      setCusName(res?.customerCompany[0]?.customerName);
    });
  };

  const getDataUser = async () => {
    setIsLoad(true);
    await getUserShop({
      customerId: id,
      position,
      text: searchText,
      isActive:
        tab === "WAIT_APPROVE" || tab === "REJECTED"
          ? false
          : tab === "APPROVED"
            ? isApproveActive
            : isActive,
      page,
      isDelete,
      statusApprove: tab || undefined,
    }).then((res) => {
      setTimeout(() => {
        setData({
          count: res?.responseData?.count,
          data: res?.responseData?.data,
          summary: res?.responseData?.summary,
        });
        setIsLoad(false);
      }, 200);
    });
  };

  useEffect(() => {
    getDataUser();
    getDataCustomer();
  }, [tab, position, searchText, isActive, page, isDelete, isApproveActive]);

  const getDataById = async (id: string) => {
    const res = await getUserShopById(id).then((res) => res);
    setEdit(res);
    form.setFieldsValue({
      userShopId: res?.userShopId,
      nametitle: res?.nametitle,
      firstname: res?.firstname,
      lastname: res?.lastname,
      nickname: res?.nickname,
      email: res?.email,
      telephone: res?.telephone,
      position: res?.position,
      isActive: res?.isActive,
    });
  };
  const ActionBtn = ({ onClick, icon }: any) => {
    return (
      <Col span={6}>
        <div className='btn btn-icon btn-light btn-hover-primary btn-sm' onClick={onClick}>
          <span
            className='svg-icon svg-icon-primary svg-icon-2x'
            style={{ color: color["primary"] }}
          >
            {icon}
          </span>
        </div>
      </Col>
    );
  };
  const changeTab = (name?: string) => {
    if (name !== "DELETED") {
      setTab(name || "");
      setIsActive(true);
      setIsDelete(false);
    } else {
      setTab("");
      setIsActive(false);
      setIsDelete(true);
    }
  };
  const PageTitle = () => {
    return (
      <>
        <PageTitleNested
          title='จัดการผู้ใช้งานลูกค้า'
          showBack
          showImg
          img={image.iconShopApp}
          onBack={() => navigate(-1)}
          customBreadCrumb={
            <BreadCrumb
              data={[
                { text: "รายชื่อร้านค้า", path: -1 },
                { text: "จัดการผู้ใช้งานลูกค้า", path: window.location.pathname },
              ]}
            />
          }
        />
        <div style={{ paddingLeft: "45px" }}>
          <Text fontWeight={600}>ชื่อร้าน : {cusName}</Text>
        </div>
      </>
    );
  };
  const dataTabs: { key: string; label: React.ReactNode }[] = isInternal
    ? [
        {
          key: "APPROVED",
          label: `อนุมัติแล้ว (${data?.summary?.approved})`,
        },
      ]
    : [
        {
          key: "WAIT_APPROVE",
          label: `รออนุมัติ (${data?.summary?.wait_approve})`,
        },
        {
          key: "APPROVED",
          label: `อนุมัติแล้ว (${data?.summary?.approved})`,
        },
        {
          key: "REJECTED",
          label: `ไม่อนุมัติ (${data?.summary?.rejected})`,
        },
        {
          key: "DELETED",
          label: `ลบผู้ใช้ (${data?.summary?.deleted})`,
        },
      ];

  const columns: any = [
    {
      title: "ชื่อผู้ใช้งาน",
      dataIndex: "name",
      key: "name",
      render: (value: any, row: any, index: number) => {
        return {
          children: (
            <Text>
              {row.userShop.nametitle || "-"} {row.userShop.firstname || ""}{" "}
              {row.userShop.lastname || ""}
            </Text>
          ),
        };
      },
    },
    {
      title: "ชื่อเล่น",
      dataIndex: "nickname",
      key: "nickname",
      render: (value: any, row: any, index: number) => {
        return {
          children: <Text>{row.userShop.nickname || "-"}</Text>,
        };
      },
    },
    {
      title: "อีเมล",
      dataIndex: "email",
      key: "email",
      render: (value: any, row: any, index: number) => {
        return {
          children: <Text>{row.userShop.email || "-"}</Text>,
        };
      },
    },
    {
      title: "เบอร์โทร",
      dataIndex: "telephone",
      key: "telephone",
      render: (value: any, row: any, index: number) => {
        return {
          children: (
            <Text>
              {row?.userShop?.telephone || "-"}
              {""}
              {(row?.userShop?.secondtelephone && ", " + row.userShop.secondtelephone) || ""}
            </Text>
          ),
        };
      },
    },
    {
      title: "บทบาท",
      dataIndex: "position",
      key: "position",
      render: (value: any, row: any, index: number) => {
        return {
          children: <Text>{row.userShop.position || "-"}</Text>,
        };
      },
    },
    {
      title: "สถานะ",
      dataIndex: "status",
      key: "status",
      render: (value: any, row: any, index: number) => {
        return {
          children: (
            <Tag color={row?.userShop?.isActive ? color.success : color.error}>
              {row?.userShop?.isActive ? "เปิดใช้งาน" : "ปิดใช้งาน"}
            </Tag>
          ),
        };
      },
    },
    {
      title: "การจัดการ",
      dataIndex: "action",
      key: "action",
      width: "10%",
      render: (value: any, row: any, index: number) => {
        return {
          children: (
            <Row justify={"center"} gutter={16}>
              <ActionBtn
                icon={<EditOutlined />}
                onClick={async () => {
                  setIsEdit(true);
                  setShowModal(true);
                  await getDataById(row.userShopId);
                }}
              />
              {tab && !isInternal && (
                <ActionBtn
                  icon={<DeleteOutlined style={{ color: color.error }} />}
                  onClick={() => {
                    setShowCancel(true);
                    setIdDelete(row.userShopId);
                  }}
                />
              )}
            </Row>
          ),
        };
      },
    },
  ];

  const errorData = () => {
    messageApi.open({
      type: "error",
      content: "เบอร์โทรศัพท์ซ้ำในระบบ กรุณาตรวจสอบใหม่อีกครั้ง",
      duration: 5,
    });
    setIsLoad(false);
  };

  const createUser = async () => {
    const payload = form.getFieldsValue(true);
    form
      .validateFields()
      .then(async (f) => {
        payload.customerId = id;
        payload.updateBy = userProfile.firstname + " " + userProfile.lastname;
        if (isEdit) {
          payload.isOwnerUpdate = false;
          await updateUserShop(payload).then((res) => {
            if (res.success) {
              setIsLoad(false);
              setShowModal(false);
              getDataUser();
              getDataCustomer();
              form.resetFields();
            } else {
              errorData();
            }
          });
        } else {
          payload.createBy = userProfile.firstname + " " + userProfile.lastname;
          payload.isOwnerCreate = false;
          await createUserShop(payload).then((res) => {
            if (res.success) {
              setIsLoad(false);
              setShowModal(false);
              getDataUser();
              getDataCustomer();
              form.resetFields();
            } else {
              errorData();
            }
          });
        }
      })
      .catch((err) => {
        setIsLoad(false);
        console.log(err);
      });
  };

  return (
    <>
      {contextHolder}
      <CardContainer>
        <PageTitle />
        <br />
        <div
          style={{
            marginTop: 24,
          }}
        >
          <Tabs items={dataTabs} onChange={changeTab} />
          <Row justify={"space-between"} gutter={8}>
            <Col span={3}>
              <Text>จำนวนผู้ใช้ทั้งหมด</Text>
            </Col>
            <Col span={3}>
              <Text>{data.count} ผู้ใช้</Text>
            </Col>
            <Col span={tab === "WAIT_APPROVE" ? 8 : tab === "APPROVED" ? 7 : 11}></Col>
            {!isInternal && (
              <>
                {tab === "APPROVED" && (
                  <Col span={3}>
                    <Select
                      allowClear
                      placeholder='สถานะ : ทั้งหมด'
                      data={
                        [
                          { key: true, value: true, label: "เปิดใช้งาน" },
                          { key: false, value: false, label: "ปิดใช้งาน" },
                        ] || []
                      }
                      style={{ width: "100%" }}
                      onChange={(e) => setIsAprroveActive(e)}
                    />
                  </Col>
                )}
                <Col span={3}>
                  <Select
                    allowClear
                    placeholder='บทบาท : ทั้งหมด'
                    data={
                      [
                        { key: "เจ้าของร้าน", value: "เจ้าของร้าน", label: "เจ้าของร้าน" },
                        { key: "พนักงาน", value: "พนักงาน", label: "พนักงาน" },
                      ] || []
                    }
                    style={{ width: "100%" }}
                    onChange={(e) => setPosition(e)}
                  />
                </Col>
              </>
            )}
            <Col span={4}>
              <Input
                placeholder='ค้นหาชื่อผู้ใช้/เบอร์โทร'
                autoComplete='off'
                suffix={<SearchOutlined style={{ fontSize: "20px", color: color.Disable }} />}
                onChange={(e) => setSearchText(e.target.value)}
              />
            </Col>
            {tab === "WAIT_APPROVE" && (
              <Col span={3}>
                <Button
                  title='เพิ่มผู้ใช้งาน'
                  icon={<PlusOutlined style={{ color: color.white }} />}
                  onClick={() => {
                    setIsEdit(false);
                    setShowModal(!showModal);
                  }}
                />
              </Col>
            )}
          </Row>
          <br />
          <TableContainer>
            <Table
              columns={tab === "APPROVED" ? columns : columns.filter((x) => x.key !== "status")}
              dataSource={data.data || []}
              pagination={{
                position: ["bottomCenter"],
                total: data.count,
                current: page,
                pageSize: 10,
                onChange: (page) => {
                  setPage(page);
                },
              }}
              loading={isLoad}
            />
          </TableContainer>
        </div>
      </CardContainer>
      {showModal && (
        <Modal
          open={showModal}
          onCancel={() => {
            form.resetFields();
            setShowModal(!showModal);
          }}
          footer={false}
          width={1000}
          title={
            <Text level={4} fontWeight={700}>
              {isEdit ? "แก้ไขผู้ใช้งาน" : "เพิ่มผู้ใช้งาน"}
            </Text>
          }
        >
          {tab === "APPROVED" && !isInternal && (
            <>
              <Header>หากมีการแก้ไขข้อมูลเบอร์โทรผู้ใช้งาน ระบบจะปรับสถานะเป็น “รออนุมัติ”</Header>
              <br />
            </>
          )}
          <Form layout='vertical' form={form}>
            <Row justify={"space-between"} gutter={8}>
              <Col span={8}>
                <Form.Item
                  name={"nametitle"}
                  label='คำนำหน้าชื่อ'
                  rules={[
                    {
                      required: true,
                      message: "*กรุณาเลือกคำนำหน้าชื่อ",
                    },
                  ]}
                >
                  <Select
                    placeholder='เลือกคำนำหน้าชื่อ'
                    data={staticData || []}
                    disabled={edit.isDelete || isInternal}
                    style={{ color: color.BK }}
                  />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  name={"firstname"}
                  label='ชื่อ'
                  rules={[
                    {
                      required: true,
                      message: "*กรุณาระบุชื่อ",
                    },
                  ]}
                >
                  <Input
                    placeholder='ระบุชื่อ'
                    autoComplete='off'
                    disabled={edit.isDelete || isInternal}
                    style={{ color: color.BK }}
                  />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  name={"lastname"}
                  label='นามสกุล'
                  rules={[
                    {
                      required: true,
                      message: "*กรุณาระบุนามสกุล",
                    },
                  ]}
                >
                  <Input
                    placeholder='ระบุนามสกุล'
                    autoComplete='off'
                    disabled={edit.isDelete || isInternal}
                    style={{ color: color.BK }}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row justify={"space-between"} gutter={8}>
              <Col span={8}>
                <Form.Item name={"nickname"} label='ชื่อเล่น'>
                  <Input
                    placeholder='ระบุชื่อเล่น'
                    autoComplete='off'
                    disabled={edit.isDelete || isInternal}
                    style={{ color: color.BK }}
                  />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  name={"email"}
                  label='อีเมล'
                  // rules={[
                  //   {
                  //     required: true,
                  //     message: "*กรุณาระบุอีเมล",
                  //   },
                  // ]}
                >
                  <Input
                    placeholder='ระบุอีเมล'
                    autoComplete='off'
                    disabled={edit.isDelete || isInternal}
                    style={{ color: color.BK }}
                  />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  name={"telephone"}
                  label='เบอร์โทร'
                  rules={[
                    {
                      required: true,
                      message: "*กรุณาระบุเบอร์โทร",
                    },
                    {
                      pattern: /^[0-9]*$/,
                      message: "กรุณากรอกเบอร์โทรศัพท์เป็นตัวเลขเท่านั้น",
                    },
                  ]}
                >
                  <Input
                    placeholder='ระบุชื่อเบอร์โทร'
                    autoComplete='off'
                    maxLength={10}
                    disabled={edit.isDelete || isInternal}
                    style={{ color: color.BK }}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Col span={24}>
              <Form.Item
                name={"position"}
                label='บทบาท'
                rules={[
                  {
                    required: true,
                    message: "*กรุณาเลือกบทบาท",
                  },
                ]}
              >
                <Select
                  style={{ width: "100%" }}
                  placeholder='เลือกบทบาท'
                  data={
                    [
                      { key: "เจ้าของร้าน", value: "เจ้าของร้าน", label: "เจ้าของร้าน" },
                      { key: "พนักงาน", value: "พนักงาน", label: "พนักงาน" },
                    ] || []
                  }
                  disabled={edit.isDelete || isInternal}
                />
              </Form.Item>
            </Col>
            {isEdit && (
              <>
                <Col span={24}>
                  <Text level={5} fontWeight={600}>
                    อัปเดทโดย
                  </Text>
                </Col>
                <Col span={24}>
                  <Text level={5}>
                    {edit?.updateBy}
                    {", "}
                    {dayjs(edit?.updateDate || "")
                      .locale("th")
                      .format("D MMM BBBB HH:MM น.")}
                  </Text>
                </Col>
                <Col span={24}>
                  <Text level={5} fontWeight={600}>
                    สถานะการตรวจสอบ
                  </Text>
                </Col>
                <Col span={24}>
                  <Text
                    level={5}
                    color={edit?.isDelete ? "error" : USER_SHOP_MAPPING_COLOR[edit?.statusApprove]}
                    fontWeight={600}
                  >
                    {edit?.isDelete ? "ลบผู้ใช้งาน" : USER_SHOP_MAPPING[edit?.statusApprove]}
                  </Text>
                </Col>
                {tab !== "WAIT_APPROVE" && !edit.isDelete && (
                  <Col span={10}>
                    <Form.Item
                      name='isActive'
                      label='สถานะการใช้งาน'
                      rules={[
                        {
                          required: true,
                          message: "*โปรดระบุสถานะ",
                        },
                      ]}
                    >
                      <Radio.Group
                        style={{ width: "100%" }}
                        disabled={edit?.statusApprove === "APPROVED" ? false : true}
                      >
                        <Space direction='vertical'>
                          <Radio value={true}>เปิดใช้งาน</Radio>
                          <Radio value={false}>ปิดใช้งาน</Radio>
                        </Space>
                      </Radio.Group>
                    </Form.Item>
                  </Col>
                )}
              </>
            )}
            <Divider />
            <Row justify='space-between' gutter={12}>
              {!edit.isDelete && (
                <>
                  <Col xl={4} sm={6}>
                    <Button
                      typeButton='primary-light'
                      title='ยกเลิก'
                      onClick={() => {
                        form.resetFields();
                        setShowModal(false);
                      }}
                    />
                  </Col>
                  <Col xl={16} sm={12}></Col>
                  <Col xl={4} sm={6}>
                    <Button
                      typeButton='primary'
                      title='บันทึก'
                      onClick={() => {
                        createUser();
                        setIsLoad(true);
                      }}
                      disabled={isLoad}
                    />
                  </Col>
                </>
              )}
            </Row>
          </Form>
        </Modal>
      )}
      {showCancel && (
        <Modal
          centered
          open={showCancel}
          closable={false}
          onOk={() =>
            deleteUserShop(idDelete).then((res) => {
              if (res.success) {
                setShowCancel(false);
                getDataUser();
              }
            })
          }
          onCancel={() => setShowCancel(false)}
          destroyOnClose
          okText={"ยืนยัน"}
          cancelButtonProps={{
            style: { color: color.primary, borderColor: color.primary },
          }}
        >
          <Text level={2}>ต้องการยืนยันการลบผู้ใช้งาน</Text>
          <br />
          <Text level={5} color='Text3'>
            โปรดตรวจสอบรายละเอียดผู้ใช้อีกครั้ง ก่อนการกดยืนยันการลบ
          </Text>
        </Modal>
      )}
    </>
  );
}

export default UserCorporateShop;
