import { Col, Form, Row } from "antd";
import dayjs from "dayjs";
import React, { useEffect, useMemo, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import styled from "styled-components";
import Swal from "sweetalert2";
import Button from "../../../components/Button/Button";
import { CardContainer } from "../../../components/Card/CardContainer";
import TextArea from "../../../components/Input/TextArea";
import CustomConfirmModal from "../../../components/Modal/CustomConfirmModal";
import PageTitleNested from "../../../components/PageTitle/PageTitleNested";
import Text from "../../../components/Text/Text";
import { shopDatasource } from "../../../datasource/ShopDatasource";
import { color, image } from "../../../resource";
import { defaultPropsForm } from "../../../utility/DefaultProps";

const Header = styled(Row)`
  border-radius: 8px;
  background-color: ${color.background1};
  padding: 20px;
  display: flex;
  gap: 16px;
  align-items: center;
`;
const Image = styled.img`
  height: 52px;
  padding: 16px 8px;
  background-color: white;
  border-radius: 8px;
`;

const Bottom = styled(Row)`
  border-top: 1px solid ${color.background2};
  padding-top: 20px;
  display: flex;
  justify-content: space-between;
`;

function ApproveTelCoporateShop(): JSX.Element {
  const [searchValue] = useSearchParams();
  const navigate = useNavigate();
  const id = searchValue.get("id");
  const profile = JSON.parse(localStorage.getItem("profile")!);
  const company = JSON.parse(localStorage.getItem("company")!);

  const [form] = Form.useForm();
  const [formReject] = Form.useForm();
  const [visibleApprove, setVisibleApprove] = React.useState(false);
  const [visibleReject, setVisibleReject] = React.useState(false);

  const [data, setData] = useState<any>();
  const [dataUser, setDataUser] = useState<any>();
  const [dataShop, setDataShop] = useState<any>();

  const mapStatus = {
    APPROVED: "อนุมัติ",
    REJECTED: "ไม่อนุมัติ",
  };

  const getData = async () => {
    const data = await shopDatasource.getApproveTelById(id || "");
    setData(data);
    const user = data?.customer?.customerToUserShops?.find((x) => x.userShopId === data.userShopId);
    setDataUser(user);
    const shop = data?.customer?.customerCompany?.find(
      (x) => `${x.company}` === `${company?.companyCode}`,
    );
    setDataShop(shop);
  };

  useEffect(() => {
    getData();
  }, []);

  const onUpdateApprove = async (isApprove: boolean, reason?: string, userShopId?: string) => {
    try {
      const res = await shopDatasource.updateApproveTel({
        id: id || "",
        isApprove,
        approveBy: `${profile?.firstname} ${profile?.lastname}`,
        reasonApprove: reason || undefined,
        userStaffId: userShopId,
      });
      if (res && res.success) {
        Swal.fire({
          title: isApprove ? "อนุมัติเบอร์โทรศัพท์สำเร็จ" : "ไม่อนุมัติเบอร์โทรศัพท์สำเร็จ",
          text: "",
          width: 300,
          timer: 2000,
          icon: "success",
          customClass: {
            title: "custom-title",
          },
          showConfirmButton: false,
        }).then(() => {
          navigate(-1);
        });
      } else {
        Swal.fire({
          title: "ไม่สามารถอนุมัติเบอร์โทรศัพท์ได้",
          text: "",
          width: 300,
          timer: 2000,
          icon: "error",
          customClass: {
            title: "custom-title",
          },
          showConfirmButton: false,
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const onFinish = async (values: { reason: string }) => {
    try {
      setVisibleApprove(false);
      await onUpdateApprove(true, values.reason, dataUser.userShopId);
      form.resetFields();
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <>
      <CardContainer>
        <PageTitleNested title='รายละเอียดร้านค้า' />
        <br />
        <Header>
          <Image src={image.empty_shop || ""} width={40} height={40} />
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "4px",
            }}
          >
            <Text level={6} fontWeight={700}>
              {dataShop?.customerName}
            </Text>
            <Row justify={"space-between"} gutter={8}>
              <Col>
                <Text level={6}>
                  ประเภทคู่ค้า :{" "}
                  <Text color='primary' level={6}>
                    {dataShop?.customerType === "DL" ? "Dealer" : "Subdealer"}
                  </Text>
                </Text>
              </Col>
              <Col>
                <Text level={6}>รหัสร้านค้า : {dataShop?.customerNo || "-"}</Text>
              </Col>
              <Col>
                <Text level={6}>เขต : {dataShop?.zone || "-"}</Text>
              </Col>
            </Row>
          </div>
        </Header>
        <br />
        <Col>
          <Text fontWeight={700}>ข้อมูลบุคคล (เจ้าของร้าน)</Text>
        </Col>
        <div
          style={{
            marginTop: 16,
          }}
        >
          <Row
            style={{
              marginBottom: 8,
              padding: "8px 0",
            }}
          >
            <Col span={5}>
              <Text color='Text3'>ชื่อเจ้าของร้าน :</Text>
            </Col>
            <Col span={19}>
              <Text>
                {data?.customer?.title || "-"} {data?.customer.ownerFirstname || ""}{" "}
                {data?.customer?.ownerLastname || ""}
              </Text>
            </Col>
          </Row>
          <Row
            style={{
              marginBottom: 8,
              padding: "8px 0",
            }}
          >
            <Col span={5}>
              <Text color='Text3'>เบอร์โทร :</Text>
            </Col>
            <Col span={19}>
              <Text>{dataUser?.userShop?.telephone || "-"}</Text>
            </Col>
          </Row>
          <Row
            style={{
              marginBottom: 8,
              padding: "8px 0",
            }}
          >
            <Col span={5}>
              <Text color='Text3'>อีเมล :</Text>
            </Col>
            <Col span={19}>
              <Text>{dataUser?.userShop?.email || "-"}</Text>
            </Col>
          </Row>
        </div>
        <br />
        <Col>
          <Text fontWeight={700}>ข้อมูลร้านค้า</Text>
        </Col>
        <div
          style={{
            marginTop: 16,
          }}
        >
          <Row
            style={{
              marginBottom: 8,
              padding: "8px 0",
            }}
          >
            <Col span={5}>
              <Text color='Text3'>ชื่อร้านค้า :</Text>
            </Col>
            <Col span={19}>
              <Text>{dataShop?.customerName || "-"}</Text>
            </Col>
          </Row>
          <Row
            style={{
              marginBottom: 8,
              padding: "8px 0",
            }}
          >
            <Col span={5}>
              <Text color='Text3'>รูปแบบการชำระ :</Text>
            </Col>
            <Col span={19}>
              <Text>
                {dataShop?.termPayment?.includes("N")
                  ? `เครดิต (ระยะเวลาชำระเงิน ${dataShop.termPayment.split("N")[1]} วัน)`
                  : "เงินสด" || "-"}
              </Text>
            </Col>
          </Row>
          <Row
            style={{
              marginBottom: 8,
              padding: "8px 0",
            }}
          >
            <Col span={5}>
              <Text color='Text3'>หมายเลขนิติบุคคล :</Text>
            </Col>
            <Col span={19}>
              <Text>{data?.customer?.taxNo || "-"}</Text>
            </Col>
          </Row>
          <Row
            style={{
              marginBottom: 8,
              padding: "8px 0",
            }}
          >
            <Col span={5}>
              <Text color='Text3'>เขต :</Text>
            </Col>
            <Col span={19}>
              <Text>{dataShop?.zone || "-"}</Text>
            </Col>
          </Row>
          <Row
            style={{
              marginBottom: 8,
              padding: "8px 0",
            }}
          >
            <Col span={5}>
              <Text color='Text3'>ที่อยู่ร้านค้า :</Text>
            </Col>
            <Col span={19}>
              <Text>
                {data?.customer?.address || "-"} ตำบล/แขวง {data?.customer?.subdistrict || ""}{" "}
                อำเภอ/เขต {data?.customer?.district} จังหวัด{data?.customer?.province}{" "}
                {data?.customer?.postcode}
              </Text>
            </Col>
          </Row>
          <Row
            style={{
              marginBottom: 8,
              padding: "8px 0",
            }}
          >
            <Col span={5}>
              <Text color='Text3'>ตำแหน่ง ละติจูด/ลองจิจูด :</Text>
            </Col>
            <Col span={19}>
              <Text>
                {data?.customer?.lat || "-"} / {data?.customer?.lag || "-"}
              </Text>
            </Col>
          </Row>
        </div>
      </CardContainer>
      <br />
      <CardContainer>
        <Col>
          <Text fontWeight={700}>รายละเอียด</Text>
        </Col>
        <div
          style={{
            marginTop: 16,
          }}
        >
          <Row
            style={{
              marginBottom: 8,
              padding: "8px 0",
            }}
          >
            <Col span={5}>
              <Text color='Text3'>ชื่อผู้ใช้งาน :</Text>
            </Col>
            <Col span={19}>
              <Text>
                {dataUser?.userShop?.nametitle || "-"} {dataUser?.userShop.firstname || ""}{" "}
                {dataUser?.userShop?.lastname || ""}
              </Text>
            </Col>
          </Row>
        </div>
        <div
          style={{
            marginTop: 16,
          }}
        >
          <Row
            style={{
              marginBottom: 8,
              padding: "8px 0",
            }}
          >
            <Col span={5}>
              <Text color='Text3'>บทบาท :</Text>
            </Col>
            <Col span={19}>
              <Text>{dataUser?.userShop?.position || "-"}</Text>
            </Col>
          </Row>
        </div>
        <div
          style={{
            marginTop: 16,
          }}
        >
          <Row
            style={{
              marginBottom: 8,
              padding: "8px 0",
            }}
          >
            <Col span={5}>
              <Text color='Text3'>เบอร์โทรศัพท์ :</Text>
            </Col>
            <Col span={19}>
              <Text>{dataUser?.userShop?.telephone || "-"}</Text>
            </Col>
          </Row>
        </div>
        <div
          style={{
            marginTop: 16,
          }}
        >
          <Row
            style={{
              marginBottom: 8,
              padding: "8px 0",
            }}
          >
            <Col span={5}>
              <Text color='Text3'>อัปเดทโดย :</Text>
            </Col>
            <Col span={19}>
              <Text>
                {dataUser?.userShop?.updateBy || "-"},{" "}
                {dayjs(dataUser?.userShop?.updateDate || "")
                  .locale("th")
                  .format("D MMM BBBB, HH:MM น.")}
              </Text>
            </Col>
          </Row>
        </div>
        {dataUser?.userShop.statusApprove === "WAIT_APPROVE" && (
          <Bottom>
            <Col>
              <Text color='Text3' level={6} fontFamily='Sarabun'>
                โปรดตรวจสอบข้อมูลให้ครบถ้วนก่อนอนุมัติ
              </Text>
            </Col>
            <Col
              style={{
                display: "flex",
                gap: 16,
                alignItems: "center",
              }}
            >
              <Button
                typeButton='danger'
                title='ไม่อนุมัติ'
                onClick={() => {
                  setVisibleReject(true);
                }}
              />
              <Button
                title='อนุมัติ'
                onClick={() => {
                  setVisibleApprove(true);
                }}
              />
            </Col>
          </Bottom>
        )}
        {dataUser?.userShop.statusApprove !== "WAIT_APPROVE" && (
          <>
            {" "}
            <div
              style={{
                borderRadius: "8px",
                border: `solid ${color.background1}`,
                paddingLeft: "12px",
              }}
            >
              <div
                style={{
                  marginTop: 16,
                }}
              >
                <Row
                  style={{
                    marginBottom: 8,
                    padding: "8px 0",
                  }}
                >
                  <Col span={5}>
                    <Text color='Text3'>สถานะการตรวจสอบ :</Text>
                  </Col>
                  <Col span={19}>
                    <Text
                      color={dataUser?.userShop?.statusApprove === "APPROVED" ? "success" : "error"}
                    >
                      {mapStatus[dataUser?.userShop?.statusApprove] || "-"}
                    </Text>
                  </Col>
                </Row>
              </div>
              <div
                style={{
                  marginTop: 16,
                }}
              >
                <Row
                  style={{
                    marginBottom: 8,
                    padding: "8px 0",
                  }}
                >
                  <Col span={5}>
                    <Text color='Text3'>เหตุผล :</Text>
                  </Col>
                  <Col span={19}>
                    <Text>{data?.reasonApprove || "-"}</Text>
                  </Col>
                </Row>
              </div>
            </div>
            <div
              style={{
                marginTop: 16,
              }}
            >
              <Row
                style={{
                  marginBottom: 8,
                  padding: "8px 0",
                }}
              >
                <Col span={5}>
                  <Text color='Text3'>สถานะการตรวจสอบโดย :</Text>
                </Col>
                <Col span={19}>
                  <Text>{data?.approveBy || "-"}</Text>
                </Col>
              </Row>
            </div>
          </>
        )}
      </CardContainer>
      <CustomConfirmModal
        bodyComponent={
          <Form {...defaultPropsForm} form={form} onFinish={onFinish}>
            <Text fontFamily='Sarabun' fontWeight={600} fontSize={16}>
              เหตุผล <span style={{ color: color.error }}>*</span>
            </Text>
            <Form.Item
              name='reason'
              rules={[
                {
                  required: true,
                  message: "โปรดระบุเหตุผล",
                },
              ]}
            >
              <TextArea placeholder='โปรดระบุเหตุผล' />
            </Form.Item>
          </Form>
        }
        visible={visibleApprove}
        onConfirm={() => {
          form.submit();
        }}
        onCancel={() => {
          setVisibleApprove(false);
        }}
        title='ยืนยันการอนุมัติ'
        desc={`โปรดระบุเหตุผลยืนยันการอนุมัติ เบอร์โทรศัพท์`}
      />
      <CustomConfirmModal
        bodyComponent={
          <Form
            {...defaultPropsForm}
            form={formReject}
            onFinish={async (values: { reason: string }) => {
              setVisibleReject(false);
              await onUpdateApprove(false, values.reason, dataUser.userShopId);
            }}
          >
            <Text fontFamily='Sarabun' fontWeight={600} fontSize={16}>
              เหตุผล <span style={{ color: color.error }}>*</span>
            </Text>
            <Form.Item
              name='reason'
              rules={[
                {
                  required: true,
                  message: "โปรดระบุเหตุผล",
                },
              ]}
            >
              <TextArea placeholder='โปรดระบุเหตุผล' />
            </Form.Item>
          </Form>
        }
        visible={visibleReject}
        onConfirm={() => {
          formReject.submit();
        }}
        onCancel={() => {
          setVisibleReject(false);
        }}
        title='ยืนยันการไม่อนุมัติ'
        desc={`โปรดระบุเหตุผลยืนยันการไม่อนุมัติ เบอร์โทรศัพท์`}
      />
    </>
  );
}

export default ApproveTelCoporateShop;
