export const inputNumberValidator = (rule: any, value: any, callback: any) => {
  if (!parseInt(value) || parseInt(value) < 1) {
    throw new Error();
  }
  return Promise.resolve();
};

export const isNumeric = (value: string) => {
  return /^-?\d+$/.test(value);
};

export function validateOnlyNumber(t: string): string {
  const inputValue = t;
  const convertedNumber = inputValue.replace(/^[^\d].[^\d]/g, "");
  return convertedNumber;
}

export function validateOnlyNumWDecimal(t: string): string {
  const inputValue = t;
  const convertedNumber = inputValue.replace(/^0+|[^0-9.]/g, "");
  return convertedNumber;
}

export function onInputNumberKeyPress(event: any): void {
  if (!/[0-9]/.test(event.key) && event.key !== "Backspace") {
    event.preventDefault();
  }
}
